import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faQuoteLeft, faQuoteRight } from '@fortawesome/free-solid-svg-icons';
import { testimonials } from '../models/testimonials.model';
import { Layout } from '../components/layout';
import SEO from '../components/seo';
import PageHeading from '../components/PageHeading';
import PageSection from '../components/PageSection';

const items = testimonials;

const Testimonials = () => (
  <Layout>
    <SEO title="Testimonials" description="Fit Bodiz Testimonials" />
    <PageSection>
      <PageHeading title="Blog" heading="Testimonials" image="AdobeStock_79367708.jpg" />

      <section className="text-center">
        <h1 className="h1">Testimonials</h1>
        <div>
          {items.map((item, i) => (
            <div key={i}>
              <div className="testimonial mt-2">
                <h4>{item.name}</h4>
                <a href={item.link} target="_blank">
                  <h6 className="blue-text font-bold">{item.source} review</h6>
                </a>
                <p>
                  <FontAwesomeIcon icon={faQuoteLeft} color="grey" />
                  <span className="ml-1 mr-1">{item.comment}</span>
                  <FontAwesomeIcon icon={faQuoteRight} color="grey" />
                </p>
                <div className="grey-text">
                  <FontAwesomeIcon icon={faStar} color="gold" />
                  <FontAwesomeIcon icon={faStar} color="gold" />
                  <FontAwesomeIcon icon={faStar} color="gold" />
                  <FontAwesomeIcon icon={faStar} color="gold" />
                  <FontAwesomeIcon icon={faStar} color="gold" />
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
    </PageSection>
  </Layout>
);

export default Testimonials;
